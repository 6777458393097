import PageForm from "../../../components/pageForm/PageForm";
import React, {useEffect, useState} from "react";
import Tooltip from "../../../components/tooltips/Tooltips";
import {
  Building,
  Calendar, ExternalLink,
  InformationCircle,
  LinkIcon, ReferenceIcon, SearchLoop,
  Signer,
  TrashIcon
} from "../../../assets/icons";
import EmptyState from "../../../components/EmptyState/EmptyState";
import ApiDatabase from "server";
import {dateToEpoch, dateWordShort} from "../../../helper/helper";
import CopyToClipboard from "../../../components/copyToClipboard";
import ThreeStateCheckbox from "../../../components/threeStateCheckbox/threeStateCheckbox";
import UseContext from "../../../hooks/useContext";
import useSlideOver from "../../../components/SlideOver/useSlideOver";
import Button from "../../../components/button";
import useModal from "../../../components/modal/useModal";
import usefetchdata from "../../../hooks/usefetchdata";
import AdminMissionDetails from "./adminMissionDetails";
import Pagination from "../../../components/pagination/pagination";
import StyledInput from "../../../components/styledInput/StyledInput";
import removeAccents from "remove-accents";
import Fuse from "fuse.js";
import TempWorkerMission from "../../../components/tempWorkerMission/tempWorkerMission";
import BadgeSquare from "../../../components/badge/badgeSquare";

const MissionPeriod = () => {
  const {objID, setObjID, searchMissionResult, setSearchMissionResult} = UseContext()
  const {toggleSlideOver} = useSlideOver();
  const [searchMissionResultWaiting, setSearchMissionResultWaiting] = useState(false);
  const [missionsToDisplay, setMissionsToDisplay] = useState([]);
  const [isMissionSearchAssigned, setIsMissionSearchAssigned] = useState(null);
  const [isMissionSearchSigned, setIsMissionSearchSigned] = useState(null);
  const [isMissionSearchSignatureSend, setIsMissionSearchSignatureSend] = useState(null);
  const [isInfoMissionWaiting, setIsInfoMissionWaiting] = useState(false);
  const [isInfoMissionLoadData, setIsInfoMissionLoadData] = useState(false);
  const [editMission, setEditMission] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [countPerPage, setCountPerPage] = useState(20)
  const [countTotal, setCountTotal] = useState(0)
  const [hoveredCompanyItems, setHoveredCompanyItems] = useState({});

  const [search, setSearch] = useState('');

  const [typeContract, setTypeContract] = useState([
    {type: "sendToSign", name: "Envoyé pour signature", checked: false},
    {type: "toBeSentToSign", name: "À envoyer pour signature", checked: false},
    {type: "signByCompany", name: "Signé par l’entreprise", checked: false},
    {type: "signByTempWorker", name: "Signé par l’intérimaire", checked: false},
    {type: "notCreated", name: "Contrat non généré", checked: false},
  ]);

  const [departments, setDepartments] = useState([
    {name: "Martinique", checked: false},
    {name: "Guadeloupe", checked: false},
  ]);

  const [missionStatus, setMissionStatus] = useState([
    {type: "all", name: "Tous les status", checked: true},
    {type: "done", name: "Terminé", checked: false},
    {type: "inProgress", name: "En cours", checked: false},
    {type: "soon", name: "À venir", checked: false},
  ]);

  const {toggle} = useModal()
  const {userData} = usefetchdata();
  const [role, setRole] = useState(500);
  const token = localStorage.getItem('xsrfToken')

  useEffect(() => {
    if (userData?.role) {
      setRole(userData.role)
    }
  }, [userData])

  const getDepartmentMissions = () => {
    ApiDatabase.getDepartmentMissions({token}, (data) => {
      if (data.departments.length > 0) {
        const departments = data.departments.map(department => ({ name: department, checked: false }));
        setDepartments(departments);
      }
    }, (err) => {
      if (err.name !== "CanceledError") {
        console.error(err);
      }
    })
  }

  const manageMissionSearch = () => {
    setSearchMissionResultWaiting(true)

    setIsInfoMissionLoadData(false)
    setIsInfoMissionWaiting(false)
    setEditMission(false)

    ApiDatabase.searchMission({token}, (data) => {
      setSearchMissionResult(data.missions)

      setTotalPages(Math.ceil(data.missions.length / countPerPage))
      setCountTotal(data.missions.length)

      if (data.missions.length === 1) {
        setEditMission(data.missions[0]._id)
      }
      setSearchMissionResultWaiting(false)
    }, (err) => {
      if (err.name !== "CanceledError") {
        setSearchMissionResultWaiting(false)
        setSearchMissionResult(false)
        console.error(err);
      }
    })
  }

  useEffect(() => {
    manageMissionSearch();
    getDepartmentMissions();
  }, [])

  useEffect(() => {
    if (searchMissionResult.length > 0) {
      setSearchMissionResultWaiting(true)

      const missionsToDisplay = [...searchMissionResult];

      const isPriorityMission = (mission) => {
        const assigned = !!mission.user?.id_bestt;
        const { datestart, dateend } = mission.duration || {};
        if (!datestart || !dateend) return false;
        const currentDate = dateToEpoch(new Date());
        const startEpoch = dateToEpoch(datestart);
        const endEpoch = dateToEpoch(dateend);
        return !assigned && startEpoch <= currentDate && currentDate <= endEpoch;
      };

      const isFutureMissionToAssign = (mission) => {
        const assigned = !!mission.user?.id_bestt;
        const { datestart } = mission.duration || {};
        if (!datestart) return false;
        const currentDate = dateToEpoch(new Date());
        const startEpoch = dateToEpoch(datestart);
        return !assigned && startEpoch > currentDate;
      };

      const missionsWithUrgency = missionsToDisplay.map((mission) => ({
        ...mission,
        is_urgent: isPriorityMission(mission),
      }));

      missionsWithUrgency.sort((a, b) => {
        const aIsPriority = a.is_urgent;
        const bIsPriority = b.is_urgent;

        const aIsFutureToAssign = isFutureMissionToAssign(a);
        const bIsFutureToAssign = isFutureMissionToAssign(b);

        if (aIsPriority && !bIsPriority) {
          return -1;
        } else if (!aIsPriority && bIsPriority) {
          return 1;
        } else if (aIsPriority && bIsPriority) {
          return dateToEpoch(a.duration.dateend) - dateToEpoch(b.duration.dateend);
        } else if (aIsFutureToAssign && !bIsFutureToAssign) {
          return -1;
        } else if (!aIsFutureToAssign && bIsFutureToAssign) {
          return 1;
        } else if (aIsFutureToAssign && bIsFutureToAssign) {
          return dateToEpoch(a.duration.datestart) - dateToEpoch(b.duration.datestart);
        } else {
          return 0;
        }
      });

      let sortedResults = missionsWithUrgency;

      if (removeAccents(search.toLowerCase()) !== "") {
        const searchTerms = search
          .split(" ")
          .map((term) => removeAccents(term.toLowerCase()));

        const options = {
          keys: [
            "id_mission",
            "user.firstname",
            "user.lastname",
            "user.email",
            "user.phone",
            "user.id_bestt",
            "title",
            "reference",
            "company.reason_social",
            "company.name_commercial",
            "contract_tempworker.id_bestt",
            "contract_company.id_bestt"
          ],
          includeScore: true,
          threshold: 0.3,
          distance: 100,
          shouldSort: true,
          getFn: (obj, path) => {
            const value = Fuse.config.getFn(obj, path);
            if (value == null) {
              return "";
            }
            return removeAccents(value.toString().toLowerCase());
          },
        };

        const fuse = new Fuse(missionsWithUrgency, options);
        const combinedResults = {};
        searchTerms.forEach((term) => {
          const result = fuse.search(term);
          result.forEach(({item, score}) => {
            const indexSearch = `${item._id}${item.id_mission}${item.title}${item.search}`;
            if (combinedResults[indexSearch]) {
              combinedResults[indexSearch].score -= score;
            } else {
              combinedResults[indexSearch] = {item, score};
            }
          });
        });

        sortedResults = Object.values(combinedResults)
          .sort((a, b) => a.score - b.score)
          .map((result) => result.item);
      }

      const isFilterChecked = (filterArray) => filterArray.some(filter => filter.checked);

      const checkContractType = (mission, typeContract) => typeContract.some(contract => {
        if (!contract.checked) return false;

        const {type} = contract;
        const hasBesttId = mission.user?.id_bestt;
        const contractCompany = mission.contract_company?.[0];
        const contractTempWorker = mission.contract_tempworker?.[0];

        switch (type) {
          case "sendToSign":
            return hasBesttId && (contractTempWorker?.yousign_id || contractCompany?.yousign_id);
          case "toBeSentToSign":
            return hasBesttId && (contractTempWorker?.id_bestt && contractCompany?.id_bestt && !contractTempWorker?.yousign_id && !contractCompany?.yousign_id);
          case "signByCompany":
            return hasBesttId && ["1", 1].includes(contractCompany?.status);
          case "signByTempWorker":
            return hasBesttId && ["1", 1].includes(contractTempWorker?.status);
          case "notCreated":
            return hasBesttId && (!contractTempWorker || !contractCompany);
          default:
            return false;
        }
      });

      const checkDepartment = (mission, departments) =>
        departments.some(department =>
          department.checked &&
          mission.address?.department &&
          removeAccents(department.name).toLowerCase() === removeAccents(mission.address.department).toLowerCase()
        );

      const checkStatus = (mission, missionStatus) => {
        const currentDate = dateToEpoch(new Date());
        const {datestart, dateend} = mission.duration;

        return missionStatus.some(status => {
          if (!status.checked) return false;

          switch (status.type) {
            case "all":
              return true;
            case "done":
              return dateToEpoch(dateend) <= currentDate;
            case "inProgress":
              return dateToEpoch(datestart) <= currentDate && dateToEpoch(dateend) >= currentDate;
            case "soon":
              return dateToEpoch(datestart) > currentDate;
            default:
              return false;
          }
        });
      };

      const missionsResult = sortedResults.filter(mission => {
        const isMissionAssigned = isMissionSearchAssigned === null ? true : isMissionSearchAssigned ? !!mission.user?.id_bestt : !mission.user?.id_bestt;
        const isTypeContract = !isFilterChecked(typeContract) || checkContractType(mission, typeContract);
        const isDepartment = !isFilterChecked(departments) || checkDepartment(mission, departments);
        const isStatus = !isFilterChecked(missionStatus) || checkStatus(mission, missionStatus);

        return isMissionAssigned && isTypeContract && isDepartment && isStatus;
      });

      setCountTotal(missionsResult.length);
      setTotalPages(Math.ceil(missionsResult.length / countPerPage));
      const start = (currentPage - 1) * countPerPage;
      const end = start + countPerPage;

      setMissionsToDisplay(missionsResult.slice(start, end));

      setSearchMissionResultWaiting(false)
    } else {
      setMissionsToDisplay([]);
    }
  }, [searchMissionResult, isMissionSearchAssigned, typeContract, departments, missionStatus, search, currentPage]);

  useEffect(() => {
    setCurrentPage(1)
  }, [isMissionSearchAssigned, typeContract, departments, missionStatus, search]);

  useEffect(() => {
    if (isMissionSearchAssigned === false) {
      setIsMissionSearchSigned(false)
      setIsMissionSearchSignatureSend(false)
    }
  }, [isMissionSearchAssigned])

  useEffect(() => {
    if (isMissionSearchSigned === true) {
      setIsMissionSearchAssigned(true)
      setIsMissionSearchSignatureSend(true)
    }
  }, [isMissionSearchSigned])

  useEffect(() => {
    if (isMissionSearchSignatureSend === true) {
      setIsMissionSearchAssigned(true)
    } else if (isMissionSearchSignatureSend === false) {
      setIsMissionSearchSigned(false)
    }
  }, [isMissionSearchSignatureSend])

  const openCompanyInfo = (id) => {
    setObjID(id)
    toggleSlideOver('get_company')
  }

  useEffect(() => {
    if (role) {
      if (role === 525) {
        setIsMissionSearchAssigned(true)
        setIsMissionSearchSignatureSend(false)
      }
    }
  }, [role])

  const shouldShowTrashButton = (mission) => {
    if (mission.status === 'cancel') return 'delete'

    const now = new Date();
    const startDate = new Date(mission.duration.datestart);
    const endDate = new Date(mission.duration.dateend);
    const hasAssignedUser = mission.user ? typeof mission.user === 'object' && true : false

    let missionStatus;
    if (now < startDate) {
      missionStatus = 'soon';
    } else if (now > endDate) {
      missionStatus = 'past';
    } else {
      missionStatus = 'in_progress';
    }

    if (missionStatus === 'soon' && hasAssignedUser) {
      return 'cancel';
    }

    if ((missionStatus === 'in_progress' || missionStatus === 'soon' || missionStatus === 'past') && !hasAssignedUser) {
      return 'delete';
    }

    return false;
  }

  const handleDeleteMission = (idMission) => {
    setObjID(idMission)
    toggle('mission_delete')
  }

  const handleCancelMission = (idMission) => {
    setObjID(idMission)
    toggle('mission_cancel')
  }

  const updateTypeContract = (updatedTypeContract) => {
    const updatedTypeContracts = [...typeContract];

    for (let i = 0; i < updatedTypeContracts.length; i++) {
      for (let j = 0; j < updatedTypeContract.length; j++) {
        if (updatedTypeContracts[i].type === updatedTypeContract[j].value) {
          updatedTypeContracts[i].checked = updatedTypeContract[j].checked;
        }
      }
    }

    for (let i = 0; i < updatedTypeContracts.length; i++) {
      if (updatedTypeContracts[i].checked) {
        setIsMissionSearchAssigned(true)
        break
      }
    }

    setTypeContract(updatedTypeContracts);
  };

  const updateDepartment = (updatedDepartment) => {
    const updatedDepartments = [...departments];

    for (let i = 0; i < updatedDepartments.length; i++) {
      for (let j = 0; j < updatedDepartment.length; j++) {
        if (updatedDepartments[i].name === updatedDepartment[j].value) {
          updatedDepartments[i].checked = updatedDepartment[j].checked;
        }
      }
    }

    setDepartments(updatedDepartments);
  };

  const updateMissionStatus = (updatedMissionStatus) => {
    const updatedMissionStatuses = [...missionStatus];

    for (let i = 0; i < updatedMissionStatuses.length; i++) {
      for (let j = 0; j < updatedMissionStatus.length; j++) {
        if (updatedMissionStatuses[i].type === updatedMissionStatus[j].value) {
          updatedMissionStatuses[i].checked = updatedMissionStatus[j].checked;
        }
      }
    }

    setMissionStatus(updatedMissionStatuses);
  };

  const openTempWorkerInfo = (id) => {
    setObjID(id)
    toggleSlideOver('get_temp_worker')
  }

  return (
    <PageForm>
      <div className={'p-3 flex flex-col w-full h-full gap-3'}>
        <div className={'flex justify-between items-center text-sm'}>
          <div className={'flex items-center gap-2'}>
            <div className={'border rounded py-2 px-4'}>
              <label className={'flex items-center gap-2 cursor-pointer'}>
                <ThreeStateCheckbox checked={isMissionSearchAssigned}
                                    onChange={setIsMissionSearchAssigned}/>
                <div className={''}>
                  Mission attribuée
                </div>
              </label>
            </div>
            <div className={`max-w-[155px]`}>
              <StyledInput
                type={"selectMultipleCheckbox"}
                value={"Contrat"}
                list={typeContract.map((contract) => {
                  return {
                    value: contract.type,
                    label: contract.name,
                    checked: contract.checked,
                  };
                })}
                onChange={(updatedTypeContract) =>
                  updateTypeContract(updatedTypeContract)
                }
              />
            </div>
            <div className={`max-w-[175px]`}>
              <StyledInput
                type={"selectMultipleCheckbox"}
                value={"Département"}
                list={departments.map((department) => {
                  return {
                    value: department.name,
                    label: department.name,
                    checked: department.checked,
                  };
                })}
                onChange={(updatedDepartment) =>
                  updateDepartment(updatedDepartment)
                }
              />
            </div>
            <div className={`max-w-[180px]`}>
              <StyledInput
                type={"selectMultipleRadio"}
                value={
                  missionStatus.filter(status => status.checked === true).length > 0
                    ? missionStatus
                      .filter(status => status.checked === true)
                      .map((status) => status.name)
                      .join(", ")
                    : "Status"
                }
                list={missionStatus.map((status) => {
                  return {
                    value: status.type,
                    label: status.name,
                    checked: status.checked,
                  };
                })}
                onChange={(updatedMissionStatus) =>
                  updateMissionStatus(updatedMissionStatus)
                }
              />
            </div>
          </div>
          <div className={'flex items-center'}>
            <div className={'w-[245px]'}>
              <StyledInput
                type={"text"}
                placeholder={"Rechercher..."}
                imgFront={<SearchLoop wh={16} color={"#6B7280"}/>}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={'flex flex-1 w-full h-full gap-3 overflow-y-auto'}>
          {
            searchMissionResultWaiting ? (
              <>
                <div className={'flex-1 h-full w-[555px] max-w-[555px] animate-pulse'}>
                  <div className={'flex flex-col gap-4 max-h-full w-full'}>
                    <div className={'flex flex-col gap-3 overflow-y-auto'}>
                      {[1, 2, 3, 4, 5].map((i, index) => (
                        <div key={index} className={`flex flex-col gap-1.5 border border-gray-100 rounded-lg p-4 cursor-pointer hover:bg-gray-50`}>
                          <div className={'flex gap-2 justify-between items-center'}>
                            <div className={'flex items-center gap-1 flex-1 min-w-0'}>
                              <div className={'truncate'}>
                                <div className={'h-4 bg-gray-200 rounded w-56'}></div>
                              </div>
                              <div>
                                <div className={'h-4 bg-gray-200 rounded w-8'}></div>
                              </div>
                            </div>
                            <div className={'flex gap-1 items-center text-sm'}>
                              <div className={'px-1.5'}>
                                <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                              </div>
                              <Button size={'SMI'} color={'DEFAULT'}>
                                <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                              </Button>
                              <Button size={'SMI'} color={'DEFAULT'}>
                                <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                              </Button>
                              <Button size={'SMI'} color={'DEFAULT'}>
                                <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                              </Button>
                            </div>
                          </div>
                          <div className={'text-sm flex items-center justify-between gap-4'}>
                            <div className={'flex flex-col gap-0.5 text-gray-700 w-1/2'}>
                              <div className={'flex gap-1 items-center'}>
                                <div className={''}>
                                  <Calendar wh={18} color={'#374151'}/>
                                </div>
                                <div className={'h-4 bg-gray-200 rounded w-56'}></div>
                              </div>
                              <div className={'flex gap-1 items-center'}>
                                <div className={''}>
                                  <Building wh={18} color={'#374151'}/>
                                </div>
                                <div className={'flex gap-1'}>
                                  <div className={'h-4 bg-gray-200 rounded w-56'}></div>
                                </div>
                              </div>
                              <div className={'flex gap-1 items-center'}>
                                <div className={''}>
                                  <Signer wh={18} color={'#374151'}/>
                                </div>
                                <div className={'h-4 bg-gray-200 rounded w-56'}></div>
                              </div>
                              <div className={'flex gap-1 items-center'}>
                                <div className={''}>
                                  <ReferenceIcon wh={18} color={'#374151'}/>
                                </div>
                                <div className={'h-4 bg-gray-200 rounded w-56'}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {
                  missionsToDisplay.length > 0 ? (
                    <>
                      <div className={'flex-1 h-full w-[555px]'}>
                        <div className={'flex flex-col gap-4 max-h-full w-full'}>
                          <div className={'flex flex-col gap-3 overflow-y-auto'}>
                            {
                              missionsToDisplay.map((mission, index) => {
                                return (
                                  <div key={index}
                                       className={`flex flex-col gap-1.5 ${editMission === mission._id ? 'border border-yellow-500' : 'border border-gray-100'} rounded-lg p-4 cursor-pointer hover:bg-gray-50`}
                                       onClick={(e) => {
                                         e.preventDefault();
                                         e.stopPropagation();
                                         setEditMission(mission._id)
                                       }}>
                                    <div className={'flex gap-2 justify-between items-center'}>
                                      <div className={'flex items-center gap-1 flex-1 min-w-0'}>
                                        <div className={'truncate'}>{mission.title}</div>
                                        <div>n°{mission.id_mission}</div>
                                      </div>
                                      <div className={'flex gap-1 items-center text-sm'}>
                                        {(mission.status !== "cancel" && mission.is_urgent) && (
                                          <BadgeSquare type={11}/>
                                        )}
                                        <div className={'border rounded px-1.5'}>
                                          {(mission.status === "cancel") ? "Annulé" : mission.users ? mission.users.length : 0}
                                        </div>
                                        {shouldShowTrashButton(mission) === 'delete' && (
                                          <Button size={'SMI'} color={'DEFAULT'} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleDeleteMission(mission._id)
                                          }}>
                                            <TrashIcon wh={20} color={'#374151'}/>
                                          </Button>
                                        )}
                                        {shouldShowTrashButton(mission) === 'cancel' && (
                                          <Button size={'SMI'} color={'DEFAULT'} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleCancelMission(mission._id)
                                          }}>
                                            <TrashIcon wh={20} color={'#374151'}/>
                                          </Button>
                                        )}
                                        <CopyToClipboard
                                          text={`${process.env.REACT_APP_INTERIM}ficheMissionSearch/${mission._id}`}>
                                          <Button
                                            size={'SMI'}
                                            color={'DEFAULT'}
                                          >
                                            <LinkIcon wh={20} color={'#374151'}/>
                                          </Button>
                                        </CopyToClipboard>
                                      </div>
                                    </div>
                                    <div className={'text-sm flex items-center justify-between gap-4'}>
                                      <div className={'flex flex-col gap-0.5 text-gray-700 w-1/2'}>
                                        <div className={'flex gap-1 items-center'}>
                                          <div className={''}>
                                            <Calendar wh={18} color={'#374151'}/>
                                          </div>
                                          {dateWordShort(mission.duration.datestart) + ' > ' + dateWordShort(mission.duration.dateend)}
                                        </div>
                                        <div className={'flex gap-1 items-center'}>
                                          <div className={''}>
                                            <Building wh={18} color={'#374151'}/>
                                          </div>
                                          <div
                                            className={'flex gap-1'}
                                            onMouseEnter={() => setHoveredCompanyItems((prev) => ({
                                              ...prev,
                                              [index + mission.company?._id]: true
                                            }))}
                                            onMouseLeave={() => setHoveredCompanyItems((prev) => ({
                                              ...prev,
                                              [index + mission.company._id]: false
                                            }))}
                                            onClick={(e) => {
                                              e.preventDefault()
                                              e.stopPropagation()
                                              openCompanyInfo(mission.company?._id)
                                            }}>
                                            {
                                              (mission.company?.reason_social === mission.company?.name_commercial) ?
                                                mission.company?.reason_social :
                                                (mission.company?.reason_social + ' => ' + mission.company?.name_commercial)
                                            }
                                            {
                                              mission.company?.id_bestt ? ' n°' + mission.company?.id_bestt : ''
                                            }
                                            {
                                              hoveredCompanyItems[index + mission.company?._id] &&
                                              <ExternalLink wh={18} color={'#235FE6'}/>
                                            }
                                          </div>
                                        </div>
                                        {mission.user && (
                                          <div className={'flex gap-1 items-center'}>
                                            <div className={''}>
                                              <Signer wh={18} color={'#374151'}/>
                                            </div>
                                            {mission.contract_tempworker[0]?.id_bestt ? `Contrat n°${mission.contract_tempworker[0]?.id_bestt}` : "Auncun contrat BeSTT"}
                                          </div>
                                        )}
                                        {mission.reference && (
                                          <div className={'flex gap-1 items-center'}>
                                            <div className={''}>
                                              <ReferenceIcon wh={18} color={'#374151'}/>
                                            </div>
                                            Référence n°{mission.reference}
                                          </div>
                                        )}
                                      </div>
                                      {mission.user && (
                                        <div
                                          className={'w-1/2 rounded-lg border border-gray-100 px-4 py-2.5 text-gray-500 hover:bg-gray-100'}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            openTempWorkerInfo(mission.user._id)
                                          }}>
                                          <TempWorkerMission user={mission.user}/>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {
                            (currentPage && totalPages > 1 && setCurrentPage) ? (
                              <Pagination currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal}
                                          totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                            ) : null
                          }
                        </div>
                      </div>
                      <div className={'flex-1 overflow-hidden'}>
                        {editMission ? (
                          <AdminMissionDetails missionId={editMission}
                                               searchMissionResult={searchMissionResult}
                                               setSearchMissionResult={setSearchMissionResult}/>
                        ) : (
                          <EmptyState type={'select_mission'}/>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <EmptyState type={'mission_search'}/>
                    </>
                  )
                }
              </>
            )
          }
        </div>
      </div>
    </PageForm>
  );
}

export default MissionPeriod;
