import React, {useEffect, useState} from 'react';
import ApiDatabase from 'server'
import {useNavigate, useParams} from "react-router-dom";
import UseContext from "hooks/useContext";
import Button from "components/button";
import Divider from "components/divider/divider";
import {toast} from "react-toastify";
import UseModal from "components/modal/useModal";
import MediaQuery from "react-responsive";
import Cardfichehour from "components/card/mission/cardfichehour";
import {dateWordMonth} from "helper/helper";
import TBodyHoursCompany from "../../../timeSheet/components/tBodyHours";
import {ArrowLeft, CheckIcon, Close} from "../../../../assets/icons";
import ValidateTime from "../../../../components/modal/dataModal/data/modalTimeSheet/validateTime";

const FicheHour = () => {
    const {
        setObjID,
        setObjType,
        refreshTime,
        setTotalH,
        content,
        timeCheck,
        setTimeCheck,
    } = UseContext()
    const {id} = useParams()
    const navigate = useNavigate();
    const [datas, setDatas] = useState([])
    const [nMission, setNMission] = useState('')
    const [valid, setValid] = useState(true)
    const {toggle} = UseModal()
    const [allTimeSheetValid, setAllTimeSheetValid] = useState(false)
    const [allTimeSheetClose, setAllTimeSheetClose] = useState(false)

    useEffect(() => {
        ApiDatabase.getTimeSheetInfo({id},
            (data) => {
                setDatas(data)
                setObjID(data[0].id_time_sheet)
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            }
        )
    }, [refreshTime, id])

    useEffect(() => {
        if (datas.length > 0) {
            if (datas[0]?.id_mission) {
                ApiDatabase.getInfoMission({id: datas[0]?.id_mission},
                    (data) => {
                        setNMission(data.title)
                        setObjType(data.title)
                    },
                    (err) => {
                        if (err.name !== "CanceledError") {
                            console.log(err)
                        }
                    })
            }
        }
    }, [datas[0]?.id_mission])

    const getSeconds = s => s.split("h").reduce((acc, curr) => acc * 60 + +curr, 0);
    useEffect(() => {
        let res = 0

        if (datas.length > 0) {
            datas[0]?.days.map(i => {
                if (i.day_hours_ent) {
                    res += getSeconds(i.day_hours_ent)
                }
                if (!i.validated) setValid(false)
                else setValid(true)
            })
        }
        setTotalH(res)
    }, [datas])

    const handleSubmit = (e) => {
        if (valid) {
            toggle('doc_u_validWeek')

        } else {
            toast.error('Veuillez compléter la semaine')
        }
    }

    const allClose = () => {
        let validateTimeSheet = false;
        for (let i in timeCheck) {
            if (timeCheck[i] !== 2) {
                validateTimeSheet = true
            }
        }

        if (validateTimeSheet) {
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: 2
                }))
            }
        } else {
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: ""
                }))
            }
        }
    }

    const allValid = () => {
        let closeTimeSheet = false;
        for (let i in timeCheck) {
            if (timeCheck[i] !== 3) {
                closeTimeSheet = true
            }
        }

        if (closeTimeSheet) {
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: 3
                }))
            }
        } else {
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: ""
                }))
            }
        }
    }

    const handleNext = (e) => {
        e.preventDefault()
        let validateTimeSheet = true
        for (let i in timeCheck) {
            if (timeCheck[i] === '') {
                validateTimeSheet = false
            }
        }
        if (validateTimeSheet) {
            setObjID(id)
            toggle('doc_u_validWeek')
        } else {
            toast.error('Veuillez valider ou refuser toutes les heures.')
        }
    }

    useEffect(() => {
        setTimeCheck({})
        datas[0]?.days?.map((i, index) =>
            setTimeCheck(prevData => ({
                ...prevData,
                [i._id]: ''
            }))
        )
    }, [datas])

    useEffect(() => {
        let valid = true
        let close = true
        for (let i in timeCheck) {
            if (timeCheck[i] !== 2) {
                valid = false
            }
            if (timeCheck[i] !== 3) {
                close = false
            }
        }
        setAllTimeSheetValid(valid)
        setAllTimeSheetClose(close)
    }, [timeCheck])

    return (
        <>
            <div
                className={'flex flex-col mb-20 tab:mb-0 gap-6 tab:gap-0 tab:flex-row tab:justify-center tab:items-center'}>
                {
                    datas.length > 0 ? (
                        <>
                            <div className={'flex gap-2 p-4'}>
                                <div className={'cursor-pointer pt-2.5'} onClick={() => navigate(-1)}>
                                    <ArrowLeft w={16} color={'#111827'}/>
                                </div>
                                <div className={'tab:w-[750px] bg-white flex flex-col gap-4'}>
                                    <div className={'flex justify-between items-center px-2 ft-2xl text-gray-900'}>
                                        Relevé n°{datas[0]?.id_time_sheet}
                                        {
                                            datas[0]?.validated > 0 ?
                                                null
                                                :
                                                <Button size={'SM'} onClick={handleSubmit}>
                                                    Valider la semaine
                                                </Button>
                                        }
                                    </div>
                                    <div className={'flex justify-between items-center px-2 ft-b text-gray-500'}>
                                        {
                                            datas[0]?.validated > 0 ?
                                                `Voici le relevé d'heure de la mission ${content.title} n°${datas[0]?.mission?.id_mission} de ${content.nameCompany}, attribuée à ${datas[0].user.firstname} ${datas[0].user.lastname}, de la semaine du ${dateWordMonth(datas[0]?.periode_start)} au ${dateWordMonth(datas[0]?.periode_end)}`
                                                :
                                                `Complétez le relevé d'heure de la mission ${content.title} n°${datas[0]?.mission?.id_mission} de ${content.nameCompany}, attribuée à ${datas[0].user.firstname} ${datas[0].user.lastname}, de la semaine du ${dateWordMonth(datas[0]?.periode_start)} au ${dateWordMonth(datas[0]?.periode_end)}`

                                        }
                                    </div>
                                    <Divider w={100}/>
                                    <div className={'flex items-center justify-between mb-4'}>
                                        <div className={'flex gap-6 items-center ft-sm text-gray-700 font-medium'}>
                                            JOUR
                                            <span
                                                className={'text-gray-500'}>{datas[0]?.total_hours_ent ? `heures travaillées (${datas[0]?.total_hours_ent})` : null}  </span>
                                        </div>
                                        <div className={'flex items-center justify-end gap-6'}>
                                            {
                                                datas[0].validated !== 2 ? (
                                                    <div onClick={allClose}
                                                         className={`flex items-center justify-center h-8 w-8 rounded-md ${allTimeSheetValid ? 'bg-red-50' : ''} cursor-pointer`}
                                                    >
                                                        <Close wh={24}
                                                               color={allTimeSheetValid ? '#B91C1C' : '#374151'}/>
                                                    </div>
                                                ) : ''
                                            }
                                            <div onClick={allValid}
                                                 className={`flex items-center justify-center h-8 w-8 rounded-md ${allTimeSheetClose ? 'bg-green-100' : ''} cursor-pointer`}
                                            >
                                                <CheckIcon w={15}
                                                           color={allTimeSheetClose ? '#047857' : '#374151'}/>
                                            </div>
                                        </div>
                                    </div>
                                    <TBodyHoursCompany champsBody={datas[0]}/>

                                    <div className={'flex'}>
                                        <div className={'ml-auto'}>
                                            <Button size={'LG'} onClick={handleNext}>
                                                Confirmer
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
            </div>
        </>
    );
};

export default FicheHour;